import React, { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Header from "../Miscellaneous/Header";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./ViewProducts.css";
import "../Miscellaneous/StaticCards.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Footer from "../Miscellaneous/Footer";
import apiFunctions from "../../global/GlobalFunction";
import CountUp from "react-countup";
import { API_URL, BASE_URL } from "../../global/Constant";
import Pagination from "./Pagination";

export const ViewProducts = () => {
  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    function handleResize() {
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let img = {
    banner: require("./banner.jpg"),
    NumberOfProducts: require("../Miscellaneous/importing per year.jpg"),
    NumberOfBranches: require("../Miscellaneous/no of Branches.jpg"),
    NumberOfCustomers: require("../Miscellaneous/no of Branches.jpg"),
    NumberOfCars: require("../Miscellaneous/number of vehicles.jpg"),
    importBanner: require("../Miscellaneous/about-us-hero.jpg"),
  };

  const { t } = useTranslation();
  const [searchparams] = useSearchParams();
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [medicineList, setMedicineList] = useState();
  const [totalMedicine, setTotalMedicine] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(6);

  useEffect(() => {
    getAllProducts();
  }, [currentPage]);

  const ViewDetail = (id) => {
    navigate({
      pathname: "/viewdetail",
      search: createSearchParams({
        id,
      }).toString(),
    });
  };

  async function getAllProducts() {
    let getAllProducts = await apiFunctions.GET_REQUEST(
      BASE_URL + API_URL.GET_ALL_PRODUCTS + `?page=${currentPage}`
    );
    let res = getAllProducts.data.products;
    let count = getAllProducts.data.totalProducts;
    console.log(res);
    setMedicineList(res);
    setTotalMedicine(count);
    return;
  }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="container">
        <Header></Header>

        {/* <div className=" row no-gutter flex-column">
          <div
            style={{
              marginTop: "110px",
            }}
          >
            <img src={img.banner} class="img-fluid" />
          </div>
        </div> */}
        <section class="page-header">
          <div
            class="container"
            style={{
              marginTop: "50px",
            }}
          >
            <div class="row no-gutter flex-column pb-3 mt-4">
              <div class="p-title">{t("Career")}</div>
            </div>
            <div class="row no-gutter flex-column">
              <img src={img.importBanner} class="img-fluid" />
            </div>
          </div>
        </section>
        <section class="scounter">
          <div class="container">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <div
                  class="row counters "
                  style={{ backgroundColor: "#224480" }}
                >
                  <div class="col-lg-3 col-md-3 col-sm-6 col-12 text-left text-sm-center mb-5">
                    <div class="inner">
                      <span class="head">{t("importedProducts")}</span>
                      <div class="number">
                        +
                        <CountUp end={1000} duration={5} />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-3 col-sm-6 col-12 text-left text-sm-center mb-5">
                    <div class="inner">
                      <span class="head">{t("Branches")}</span>
                      <div class="number">
                        +
                        <CountUp end={50} duration={5} />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-6 col-12 text-left text-sm-center mb-5">
                    <div class="inner">
                      <span class="head">{t("Vehicles")}</span>
                      <div class="number">
                        +
                        <CountUp end={100} duration={5} />
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-md-3 col-sm-6 col-12 text-left text-sm-center mb-5">
                    <div class="inner">
                      <span class="head">{t("Cutsomers")}</span>
                      <div class="number">
                        +
                        <CountUp end={1861} duration={5} />
                      </div>
                      {/* <p>{t("Cutsomers")}</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
          </div>
        </section>

        <section class="content py-5">
          <div class="container">
            <div class="row">
              <div class="col-sm-3 ">
                <img
                  className="img-fluid imgStyle"
                  src={img.NumberOfProducts}
                  alt="imp"
                />
              </div>
              <div class="col-sm-3 ">
                <img
                  className="img-fluid imgStyle"
                  src={img.NumberOfBranches}
                  alt="imp"
                />
              </div>
              <div class="col-sm-3 ">
                <img
                  className="img-fluid imgStyle"
                  src={img.NumberOfCars}
                  alt="imp"
                />
              </div>
              <div class="col-sm-3 ">
                <img
                  className="img-fluid"
                  src={img.NumberOfCustomers}
                  alt="imp"
                />
              </div>
            </div>
          </div>
        </section>

        <div className="container py-5 text-center">
          <div className="row">
            {medicineList?.map((x, index) => {
              return (
                <>
                  <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                    <div className="medicine-inner mb-4">
                      <img
                        className="img-fluid"
                        src={x.productImg}
                        alt="product"
                      />
                      <h4
                        className="text-center py-2"
                        onClick={() => ViewDetail(x._id)}
                      >
                        {x.productName}
                      </h4>
                      <button
                        className="mb-3"
                        onClick={() => ViewDetail(x._id)}
                      >
                        {t("viewDetailsbtn")}
                      </button>
                    </div>
                    {/* <Card
                      className="border  col-md-4 mb-4 mt-4"
                      style={{
                        width: "20rem",
                        borderStyle: "none",
                        maxHeight: "350px",
                      }}
                    >
                      <Card.Img
                        style={{
                          width: "auto",
                          maxWidth: "100%",
                          height: "250px",
                          padding: "30px",
                          alignSelf: "center",
                        }}
                        variant="top"
                        src={x.productImg}
                      />
                      <Card.Body>
                        <p
                          className="text-center cartStyle"
                          style={{ cursor: "pointer" }}
                          onClick={() => ViewDetail(x._id)}
                        >
                          {x.productName}
                        </p>

                        <p
                          onClick={() => ViewDetail(x._id)}
                          className="text-center viewDetailText"
                        >
                          {t("viewDetailsbtn")}
                        </p>
                      </Card.Body>
                    </Card> */}
                  </div>
                </>
              );
            })}
          </div>
        </div>

        <Pagination
          total={totalMedicine}
          currentPage={currentPage}
          perPage={perPage}
          onPageChange={handlePageChange}
        ></Pagination>
      </div>
      <Footer></Footer>
    </>
  );
};
